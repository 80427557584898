import {PropsWithChildren} from "react";
import clsx from "clsx";


export function Paper({children, className}: PropsWithChildren & { className?: string }) {
    return (
        <div className={clsx("bg-base-100 border border-base-300 rounded-box", className)}>
            {children}
        </div>
    )
}